label {
  width: 150px;
  display: inline-block;
  padding: 5px;
  vertical-align: middle;
}

#login-button, #register-button {
  margin: 5px;
  padding: 8px 15px 8px 15px;
}

button {
  background-color: #00719c;
  color:#ffffff;
  border: none;
}

.back {
  margin-left: 5px;
  display: inline-block;
  margin-top: 20px;
  padding: 8px 15px 8px 15px;
  background-color: #262727;
}

.links {
  display: inline-block;
  padding-right: 15px;
  color: lightgray;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #00719c;
  border-radius: 50%;
  animation: spinner 0.5s linear infinite;
  
}
.spinner-container {
  margin: 100px;
}

#usernameError, #passwordError {
  font-size: 12px;
  font-weight: bold;
  color: red;
  margin-left: 160px;
  width: 270px;
  display: block;
  padding: 2px;
}

.errors {
  margin-top: 50px;
  margin-left: 40px;
}

main {
  margin-left: 40px;
}

.remove.icon {
  color: black;
  position: absolute;
  margin-left: 3px;
  margin-top: 10px;
  background-color: #000;
}

.remove.icon:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #000;
  background-color: currentColor;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.remove.icon:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #000;
  background-color: currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.wrap {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.navigation a { color: white; }
.navigation a:hover { color: lightgray; }
td { padding-right: 30px; max-width: 380px; }
tr { max-width: 350px; }
.border-bottom td {
  border-bottom: 1px solid black;
}

textarea {
  margin-top: 5px;
  min-width: 242px;
  min-height: 40px;
  vertical-align: middle;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

#name {
  min-width: 240px;
}